<template>
  <div>
    <b-row
      class="match-height"
    >
      <template
        v-for="app in apps"
      >
        <b-col
          v-if="app.id === 1"
          :key="app.id"
          cols="12"
          md="3"
          lg="3"
        >
          <b-card
            class="card-profile"
          >
            <div class="profile-image-wrapper">
              <div class="profile-image p-0">
                <div class="b-avatar badge-light rounded-circle" style="width: 114px; height: 114px;color:#000000">
                  <feather-icon
                    icon="DownloadIcon"
                    size="30"
                  />
                </div>
              </div>
            </div>
            <h3>{{ app.name }}</h3>
            <h6 class="text-muted">
              {{ app.description }}
            </h6>
            <b-badge
              class="profile-badge"
              variant="light-primary"
            >
              Pro Level
            </b-badge>
            <hr class="mb-2">

            <!-- follower projects rank -->
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Followers
                </h6>
                <h3 class="mb-0">
                  10.3k
                </h3>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Projects
                </h6>
                <h3 class="mb-0">
                  156
                </h3>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Rank
                </h6>
                <h3 class="mb-0">
                  23
                </h3>
              </div>
            </div>
            <!--/ follower projects rank -->
          </b-card>
        </b-col>
        <b-col
          v-if="app.id === 1"
          :key="app.id"
          cols="12"
          md="3"
          lg="3"
        >
          <b-card no-body>
            <b-card-body>
              <b-card-title>{{ app.name }}</b-card-title>
              <b-card-sub-title>Support card subtitle</b-card-sub-title>
            </b-card-body>
            <b-img
              fluid
              :src="app.logoURL"
              alt="Card image cap"
            />
            <b-card-body>
              <b-card-text>{{ app.description }}.</b-card-text>
              <b-link class="card-link">
                Card link
              </b-link>
              <b-link class="card-link">
                Another link
              </b-link>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          v-if="app.id === 2"
          :key="app.id"
          cols="12"
          md="3"
          lg="3"
        >
          <b-card no-body>
            <b-card-body>
              <b-card-title>{{ app.name }}</b-card-title>
              <b-card-sub-title>Support card subtitle</b-card-sub-title>
            </b-card-body>
            <b-img
              fluid
              :src="app.logoURL"
              alt="Card image cap"
            />
            <b-card-body>
              <b-card-text>{{ app.description }}.</b-card-text>
              <b-link class="card-link">
                Card link
              </b-link>
              <b-link class="card-link">
                Another link
              </b-link>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          v-if="app.id === 3"
          :key="app.id"
          cols="12"
          md="3"
          lg="3"
        >
          <b-card no-body>
            <b-card-body>
              <b-card-title>{{ app.name }}</b-card-title>
              <b-card-sub-title>Support card subtitle</b-card-sub-title>
            </b-card-body>
            <b-img
              fluid
              :src="app.logoURL"
              alt="Card image cap"
            />
            <b-card-body>
              <b-card-text>{{ app.description }}.</b-card-text>
              <b-link class="card-link">
                Card link
              </b-link>
              <b-link class="card-link">
                Another link
              </b-link>
            </b-card-body>
          </b-card>
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardSubTitle,
  BCardText,
  BCardTitle,
  BCol,
  BImg,
  BLink,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

import { onUnmounted } from '@vue/composition-api'
import appsStoreModule from '@/views/pages/apps/appsStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      apps: [],
    }
  },
  beforeMount() {
    this.getAvailableApps()
  },
  methods: {
    getAvailableApps() {
      store
        .dispatch('app-apps/fetchApps')
        .then(response => {
          this.apps = response.data.items
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen Apps',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
  },
  setup() {
    const APPS_APP_STORE_MODULE_NAME = 'app-apps'

    // Register module
    if (!store.hasModule(APPS_APP_STORE_MODULE_NAME)) store.registerModule(APPS_APP_STORE_MODULE_NAME, appsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APPS_APP_STORE_MODULE_NAME)) store.unregisterModule(APPS_APP_STORE_MODULE_NAME)
    })
  },
}

</script>
<style lang="scss" scoped>
.card.card-profile {
  padding-top: 80px;
}
</style>
